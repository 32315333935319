import { Api } from "../services"
import * as config from "./config"
import { Environment } from "./environment"

//MOBILE ONLY
// pass all unhandled promise rejections through reactotron
// see: https://github.com/infinitered/reactotron/issues/578
// if (process.env.NODE_ENV == "development") {
//   const rejectionTracking = require("promise/setimmediate/rejection-tracking");

//   if (rejectionTracking && rejectionTracking.enable) {
//     rejectionTracking.enable({
//       allRejections: true,
//       onUnhandled: (id: string, error: string) => {
//         //@ts-ignore
//         console.tron && console.tron.reportError(error);
//       },
//       onHandled: () => {},
//     });
//   }
// }

export function setupEnvironment() {
  const environment = new Environment()
  environment.api = new Api(config.api)
  return environment
}
