import { ChevronDownIcon } from "@chakra-ui/icons"
import { Box, Text, Spinner, Flex, Menu, MenuItem, MenuButton, MenuList, Portal, BoxProps } from "@chakra-ui/react"
import { observer } from "mobx-react"
import React, { useEffect } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useMst } from "../../../../lib/hooks/use-mst"
import { IOrderTemplate } from "../../../../types"

interface OrderTemplateSelectProps extends BoxProps {
  selectedOrderTemplate: IOrderTemplate
  onSetSelectedTemplateId: (id: number) => void
  forSavedCart?: boolean
  compact?: boolean
}

export const OrderTemplateSelect = observer(
  ({
    onSetSelectedTemplateId,
    forSavedCart,
    selectedOrderTemplate,
    compact,
    ...boxProps
  }: OrderTemplateSelectProps) => {
    const {
      orderTemplateStore,
      userStore: {
        currentUser: { erpCustomer },
      },
    } = useMst()

    const getNextOrderTemplatesPage = forSavedCart
      ? orderTemplateStore.getNextSavedCartsPage
      : orderTemplateStore.getNextOrderTemplatesPage
    const orderTemplates = forSavedCart ? orderTemplateStore.savedCarts : orderTemplateStore.orderTemplates
    const hasMoreOrderTemplatePages = forSavedCart
      ? orderTemplateStore.hasMoreSavedCartPages
      : orderTemplateStore.hasMoreOrderTemplatePages
    const getInitialOrderTemplatesPage = forSavedCart
      ? orderTemplateStore.getInitialSavedCartsPage
      : orderTemplateStore.getInitialOrderTemplatesPage

    const { isLoading } = orderTemplateStore
    const boxId = forSavedCart ? "savedCartScrollableBox" : "orderTemplateScrollableBox"

    useEffect(() => {
      const fetchAndSelectInitialOrderTemplates = async () => {
        const retrievedInitialTemplates = await getInitialOrderTemplatesPage()
        retrievedInitialTemplates && retrievedInitialTemplates.length > 0
          ? onSetSelectedTemplateId(retrievedInitialTemplates[0].id)
          : onSetSelectedTemplateId(null)
      }
      fetchAndSelectInitialOrderTemplates()
    }, [erpCustomer]) //if the client is changed, trigger a refresh of order templates
    return (
      <Box w="full" bg={"background.brandGray3a"} my={6} p={4} {...boxProps}>
        {!(orderTemplates.length > 0) && !isLoading ? (
          <Text textAlign="left" color="gray.600">
            {`No existing ${forSavedCart ? "rep saved carts" : "order templates"}`}
          </Text>
        ) : (
          <Menu>
            {!compact && (
              <Text textAlign="left" color="gray.600">
                {forSavedCart ? "Rep Saved Cart:" : "Template:"}
              </Text>
            )}
            <MenuButton
              pl={3}
              color="gray.500"
              bg="white"
              border="1px solid #D6D6D6"
              w="full"
              textAlign="left"
              h="50px"
            >
              <Flex justify="space-between">
                {selectedOrderTemplate && <TemplateOption orderTemplate={selectedOrderTemplate} />}
                <ChevronDownIcon h={6} w={8} />
              </Flex>
            </MenuButton>
            <Portal>
              <MenuList w="full" minW={400} zIndex="9999">
                <Flex id={boxId} overflow="auto" h={200} direction="column">
                  <InfiniteScroll
                    dataLength={orderTemplates.length} //This is important field to render the next data
                    next={getNextOrderTemplatesPage}
                    hasMore={hasMoreOrderTemplatePages}
                    style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
                    loader={<Spinner color="brand.orange" />}
                    scrollableTarget={boxId}
                    endMessage={<Text textAlign="center">end of list</Text>}
                  >
                    {orderTemplates.map((opt, i) => (
                      // TODO: How to make the menu items full width?
                      <MenuItem key={opt.id} w="full" onClick={() => onSetSelectedTemplateId(opt.id)}>
                        <TemplateOption orderTemplate={opt} />
                      </MenuItem>
                    ))}
                  </InfiniteScroll>
                </Flex>
              </MenuList>
            </Portal>
          </Menu>
        )}
      </Box>
    )
  },
)

interface ITemplateOptionProps {
  orderTemplate: IOrderTemplate
}

export const TemplateOption = (props: ITemplateOptionProps) => {
  const { orderTemplate: ot } = props
  return (
    <Flex justify="space-between" w="full">
      <Text>
        <strong>{ot.name}</strong>
        {` (${ot.orderTemplateItems?.length} products)`}
      </Text>
    </Flex>
  )
}
