import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons"
import { Button, Flex, Text } from "@chakra-ui/react"
import { observer } from "mobx-react"
import { useMemo } from "react"
import {
  generatePages,
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
} from "../../third-party/ajna/pagination/src"

interface IPaginatorProps {
  page: number
  totalPages: number
  handlePageChange: (any) => void
  resultsCount: number
}

export const Paginator = observer(({ page, totalPages, handlePageChange, resultsCount }: IPaginatorProps) => {
  const pages = useMemo(
    () =>
      generatePages({
        currentPage: page,
        pagesCount: totalPages,
        innerLimit: 2,
        outerLimit: 2,
      }),
    [page, totalPages],
  )

  return (
    <Flex flexDirection="column" alignItems={"center"} mt={2}>
      <Pagination isDisabled={false} currentPage={page} pagesCount={totalPages} onPageChange={handlePageChange}>
        <PaginationContainer align="center" justify="center">
          <PaginationPrevious w="0%" mr={3} bgColor="background.white" borderRadius={"2px"}>
            <Button
              bgColor="background.brandOrange"
              color="background.white"
              w="50%"
              h="90%"
              _selected={{ color: "background.brandGray1", bgColor: "#F2F2F2" }}
              _hover={{ bgColor: "background.brandOrange" }}
              borderRadius={"2px"}
            >
              <ChevronLeftIcon w={5} h={5} />
            </Button>
          </PaginationPrevious>
          <PaginationPageGroup>
            {pages.map((page: number) => (
              <PaginationPage
                key={`pagination_page_${page}`}
                page={page}
                px={3}
                bgColor="background.white"
                color="gray.500"
                fontWeight="normal"
                _active={{ color: "gray.500" }}
                _selected={{ color: "gray.500" }}
                _hover={{ bgColor: "#FFFFFF" }}
                _current={{ color: "background.brandOrange", fontWeight: "bold" }}
              />
            ))}
          </PaginationPageGroup>
          <PaginationNext bgColor="background.white" w="0%" ml={3} borderRadius={"2px"}>
            <Button
              bgColor="background.brandOrange"
              color="background.white"
              w="50%"
              h="90%"
              _selected={{ color: "background.brandGray1", bgColor: "#F2F2F2" }}
              _hover={{ bgColor: "background.brandOrange" }}
              borderRadius={"2px"}
            >
              <ChevronRightIcon w={5} h={5} />
            </Button>
          </PaginationNext>
        </PaginationContainer>
      </Pagination>
      <Text color="gray.400" lineHeight="21.87px" fontSize="18px">
        {resultsCount} Product(s) found
      </Text>
    </Flex>
  )
})
