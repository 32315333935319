import { flow, Instance, types } from "mobx-state-tree"
import { saveCredentials } from "../app/services"
import { withEnvironment, withRootStore } from "../lib"
import { UserModel, IUser } from "../models/index"
import posthog from "posthog-js"
import * as Sentry from "@sentry/browser"

export const UserStoreModel = types
  .model("UserStoreModel")
  .props({
    currentUser: types.maybeNull(UserModel),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .actions((self) => ({
    setCurrentUser(user: IUser) {
      self.currentUser = user
      if (process.env.NEXT_PUBLIC_POSTHOG) {
        posthog.identify(
          user.id.toString(), // distinct_id, required
          { email: user.email, name: user.name, role: user.role },
        )
      }
      if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
        Sentry.setUser({ id: user.id.toString(), email: user.email })
      }
    },
    clearCurrentUser() {
      self.currentUser = null
      if (process.env.NEXT_PUBLIC_POSTHOG) {
        posthog.reset()
      }
      if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
        Sentry.setUser(null)
      }

      // call posthog. logout or reset
    },
  }))
  .actions((self) => ({
    changePassword: flow(function* (newPassword: string, newPasswordConfirmation: string) {
      try {
        const { currentUser } = self.rootStore.userStore
        const response = yield self.environment.api.changePassword(newPassword, newPasswordConfirmation)
        if (response.ok) {
          saveCredentials(currentUser.email, newPassword)
          return response
        }
      } catch {
        // error messaging handled by API monitor
      }
    }),
  }))

export interface IUserStore extends Instance<typeof UserStoreModel> {}
