import { Button, ButtonProps } from "@chakra-ui/react"
import NextLink from "next/link"

interface ILinkButtonProps extends ButtonProps {
  onClick?: (any) => void
  href?: string
  children?: React.ReactNode
  icon?: React.ReactElement
}

export const LinkButton = ({ onClick, children, icon, href, isDisabled, ...rest }: ILinkButtonProps) => {
  const handleClick = (event) => {
    event.preventDefault()
    !isDisabled && onClick && onClick(event)
  }
  return (
    <Button
      as={NextLink}
      href={href || "#"}
      _hover={{ bg: "background.white", textDecoration: "underline" }}
      leftIcon={icon}
      background={"background.white"}
      py={3}
      px={1}
      color="utility.link"
      isDisabled={isDisabled}
      {...rest}
      onClick={onClick && handleClick}
    >
      {children}
    </Button>
  )
}
