import { flow, Instance, types } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../lib"
import { SalesOrderModel } from "../models"

export const SalesOrderStoreModel = types
  .model("SalesOrderStoreModel")
  .props({
    currentOrders: types.maybeNull(types.array(SalesOrderModel)),
    pastOrders: types.maybeNull(types.array(SalesOrderModel)),
    currentSalesOrder: types.maybeNull(types.reference(SalesOrderModel)),
    startDate: types.maybeNull(types.string),
    endDate: types.maybeNull(types.string),
    isSearching: types.optional(types.boolean, false),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    get pastOrdersNotEmpty() {
      return self.pastOrders?.filter((order) => {
        return order.subtotal != "0" && order.total != "0" && order.totalIncludeTax != "0"
      })
    },
  }))
  .actions((self) => ({
    fetchSalesOrders: flow(function* (params) {
      self.isSearching = true
      const response = yield self.environment.api.searchSalesOrders(params)
      if (response.ok) {
        self.currentOrders = response.data.currentOrders
        self.pastOrders = response.data.pastOrders

        // manually calculate tax as it is not provided from the api call
        {
          self.currentOrders?.map((orders) => {
            orders.calculateTax()
          })
        }
        {
          self.pastOrders?.map((orders) => {
            orders.calculateTax()
          })
        }
        self.startDate = params.orderDateGteq
        self.endDate = params.orderDateLteq
      }
      self.isSearching = false
    }),
    setCurrentSalesOrder(salesOrder) {
      self.currentSalesOrder = salesOrder?.id
    },
    resetCurrentSalesOrder() {
      self.currentSalesOrder = null
    },
  }))

export interface ISalesOrderStore extends Instance<typeof SalesOrderStoreModel> {}
