import { format } from "date-fns"
import { Instance, types } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../lib"
import { EPromotionStatus, IProduct, IPromotionGroup, IPromotionSection } from "../types"

export const PromotionModel = types.snapshotProcessor(
  types
    .model("PromotionModel")
    .props({
      id: types.identifierNumber,
      name: types.string,
      number: types.optional(types.string, "Unnumbered"),
      dimensionCode: types.optional(types.string, "Uncoded"),
      details: types.optional(types.string, "Select from products below"),
      brand: types.maybeNull(types.string),
      slug: types.maybeNull(types.string),
      pdfUrl: types.maybeNull(types.string),
      termsPrompt: types.maybeNull(types.string),
      termsExplanation: types.maybeNull(types.string),
      termsOptions: types.optional(types.array(types.string), []),
      startDate: types.Date,
      endDate: types.Date,
      status: types.enumeration<EPromotionStatus>(Object.values(EPromotionStatus)),
      coverImageUrl: types.maybeNull(types.string),
      desktopHeroUrl: types.maybeNull(types.string),
      mobileHeroUrl: types.maybeNull(types.string),
      tags: types.optional(types.array(types.string), []),
      promotionGroup: types.frozen<IPromotionGroup>(),
      featured: types.maybeNull(types.boolean),
      sections: types.maybeNull(types.array(types.frozen<IPromotionSection>())),
      redemptions: types.optional(types.number, 0),
      maxRedemptions: types.maybeNull(types.number),
    })
    .extend(withRootStore())
    .extend(withEnvironment())
    .views((self) => ({
      get availabilityString() {
        return `${self.redemptions} of ${self.maxRedemptions} Offer${self.maxRedemptions > 1 ? "s" : ""} Redeemed`
      },
      validDateString(dateFormat = "MMM d", separator = "-") {
        return `Valid ${format(self.startDate, dateFormat)} ${separator} ${format(self.endDate, dateFormat)}`
      },
      startDateString(dateFormat = "MMM d") {
        return format(self.startDate, dateFormat)
      },
      endDateString(dateFormat = "MMM d") {
        return format(self.endDate, dateFormat)
      },
      get comingSoon() {
        return new Date() < self.startDate
      },
      get expired() {
        return new Date() > self.endDate
      },
      get maxRedemptionsReached() {
        if (self.maxRedemptions == null) return false

        return self.redemptions >= self.maxRedemptions
      },
      get isPreview() {
        return self.status === EPromotionStatus.preview
      },
    }))
    .views((self) => ({
      get extendedTags() {
        return [...self.tags, self.comingSoon && "Coming Soon", self.expired && "Expired"].filter(
          (notFalsy) => notFalsy,
        )
      },
    }))
    .views((self) => ({
      get qualifierProducts(): IProduct[] {
        return self.sections.reduce((acc, cur) => {
          const accBlock = acc.concat(cur.qualifierLineItems?.map((li) => li.product) || [])
          const pigBlock = cur.itemGroups.reduce((pigAcc, pigCur) => {
            return pigAcc.concat(pigCur.lineItems.map((pli) => pli.product))
          }, [])
          return accBlock.concat(pigBlock)
        }, [])
      },
    }))
    .actions((self) => ({})),
  {
    preProcessor(snapshot: any) {
      return {
        ...snapshot,
        startDate: snapshot && new Date(snapshot.startDate),
        endDate: snapshot && new Date(snapshot.endDate),
      }
    },
  },
)

export interface IPromotion extends Instance<typeof PromotionModel> {}
