import { Instance, types } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../lib"
import { createStandaloneToast } from "@chakra-ui/react"
import { MessageType, ChakraMessageType } from "../types"
import { theme } from "../../styles/theme"

const toast = createStandaloneToast({ theme })

export const FlashMessageModel = types
  .model("FlashMessageModel")
  .props({})
  .extend(withRootStore())
  .extend(withEnvironment())
  .actions((self) => ({
    show(
      title: string,
      message: string,
      type: MessageType,
      options: Partial<any>, //<MessageOptions>
    ) {
      if (!type) return

      const typeLookup = (msgType: MessageType): ChakraMessageType => {
        const lookup = {
          none: "info",
          default: "info",
          info: "info",
          success: "success",
          warning: "warning",
          danger: "error",
          error: "error",
        }
        return lookup[msgType] as ChakraMessageType
      }

      toast({
        title,
        description: message,
        status: typeLookup(type),
        duration: typeLookup(type) == "info" ? 3500 : 5000,
        isClosable: true,
        position: "bottom-left",
      })
    },
    showAddToCart(
      title: string,
      message: string,
      type: MessageType,
      options: Partial<any>, //<MessageOptions>
    ) {
      const typeLookup = (msgType: MessageType): ChakraMessageType => {
        const lookup = {
          none: "info",
          default: "info",
          info: "info",
          success: "success",
          warning: "warning",
          danger: "error",
          error: "error",
        }
        return lookup[msgType] as ChakraMessageType
      }

      toast({
        description: message,
        status: typeLookup(type),
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      })
    },
  }))

export interface IFlashMessage extends Instance<typeof FlashMessageModel> {}
