import { flow, Instance, types } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../lib"
import { IAnipetRep, IClient } from "../types"
import { numericDateToStringFormat } from "../utils/formatter"
import { orderDateRangeStartDefault, datePickerMaxDate } from "../utils/date-defaults"

export const UserModel = types
  .model("UserModel")
  .props({
    id: types.identifierNumber,
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    address: types.maybeNull(types.string),
    state: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    phoneNumber: types.maybeNull(types.string),
    anipetRep: types.maybeNull(types.frozen<IAnipetRep>()),
    erpCustomer: types.maybeNull(types.frozen<IClient>()),
    role: types.optional(types.string, "customer"),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    get isRep() {
      return self.role != "customer"
    },
    // used to display Your Anipet Rep sidebar menu on my-account
    // allow customers and vendor_reps to see this
    get allowedToViewYourAnipetSideBarMenu() {
      return self.role == "customer" || self.role == "vendor_rep"
    },
  }))
  .views((self) => ({
    get canAddToCart() {
      return !self.isRep || !!self.erpCustomer
    },
    get name() {
      return `${self.firstName} ${self.lastName}`
    },
    get number() {
      return self.erpCustomer?.number
    },
  }))
  .actions((self) => ({
    setCurrentClient: flow(function* (clientId?: number | string | string[]) {
      const response = yield self.environment.api.setCurrentClient(clientId)
      if (response && response.ok) {
        self.erpCustomer = response.data
        self.environment.api.setClientHeader(response.data.number)
        self.rootStore.catalogueStore.updateSuggestedProducts(response.data.suggestedProducts || [])
        self.rootStore.cartStore.setCart(response.data.cart)
        self.rootStore.salesOrderStore.fetchSalesOrders({
          orderDateGteq: numericDateToStringFormat(orderDateRangeStartDefault()),
          orderDateLteq: numericDateToStringFormat(datePickerMaxDate()),
        })
        sessionStorage.setItem("sessionSavedId", response.data.number)
        //if the customer is blocked, the backend will rais a meta error message
      }
      return response
    }),
    clearCurrentClient: flow(function* () {
      const response = yield self.environment.api.clearCurrentClient()
      if (response && response.ok) {
        self.erpCustomer = null
        self.environment.api.clearClientHeader()
        self.rootStore.catalogueStore.updateSuggestedProducts([])
        self.rootStore.cartStore.clearCart()
      }
      return response
    }),
  }))

export interface IUser extends Instance<typeof UserModel> {}
