import { Image, Link, SimpleGrid } from "@chakra-ui/react"
import { useState, useEffect } from "react"
import { useMst } from "../../../../lib/hooks/use-mst"

export const InstagramFeed = (): JSX.Element => {
  const { catalogueStore } = useMst()
  const { instagramImages } = catalogueStore
  const [csrInstagramImages, setCsrInstagramImages] = useState([])

  useEffect(() => setCsrInstagramImages(instagramImages), [instagramImages])

  return (
    <SimpleGrid flex={1} columns={3} spacing={1} mb={{ base: 6, lg: 0 }}>
      {csrInstagramImages.map((image) => (
        <Link href={image.instagramLink} isExternal key={image.imageUrl}>
          <Image
            src={image.imageUrl}
            alt="Latest Instagram Media"
            boxSize={{ base: "calc(100vw*.31)", lg: 157 }}
            objectFit="cover"
          />
        </Link>
      ))}
    </SimpleGrid>
  )
}
