import * as humps from "humps"
import * as R from "ramda"

//add depth max to be 10, likely has to do with d365 nested objects
export const camelizeResponse = (data: { [key: string]: any }) => {
  return humps.camelizeKeys(data, { depth: 10 })
}

export const decamelizeRequest = (params: { [key: string]: any }) => {
  return humps.decamelizeKeys(params, { depth: 10 })
}

export const eqValues: (...args: any[]) => boolean = R.compose(R.isEmpty, R.symmetricDifference)

export const extractNativeTags = (component) => {
  let results: any = []
  const getNativeTags = (el) => {
    if (el._nativeTag) {
      results.push(el._nativeTag)
    } else {
      results.push(el)
    }

    if (el._children) {
      results.concat(el._children.map(getNativeTags))
    }

    return results
  }

  return getNativeTags(component)
}

export const formatOsName = (osName: string) => {
  if (osName.toLowerCase() === "ios") {
    return "iOS"
  } else if (osName.toLowerCase() === "android") {
    return "Android"
  }
}
export const localStorageTest = () => {
  var test = "test"
  try {
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}

export const priceStringToNumber = (priceString) => {
  if (!priceString) return

  return parseFloat(priceString.replace("$", "").replace(",", ""))
}
