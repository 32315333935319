import { Box, Flex, useColorModeValue, Show } from "@chakra-ui/react"
import React, { useEffect, useState, useRef } from "react"
import { useMst } from "../../lib/hooks/use-mst"
import { observer } from "mobx-react"
import { DesktopHeader, DesktopNav } from "./shared/nav/desktop"
import { MobileHeader } from "./shared/nav/mobile"
import { NAV_ITEMS } from "./shared/nav/nav-items"
import { WarningBanner } from "./shared/warning-banner"
import { SearchBar } from "./shared/search/search-bar"
import { SelectClient } from "./shared"
import NoSSR from "react-no-ssr"

export const NavBar = observer((): JSX.Element => {
  const [useLargeHeader, setUseLargeHeader] = useState(true)
  const {
    catalogueStore,
    userStore,
    cartStore: { productCount: cartProductCount },
  } = useMst()
  const searchInputRef = useRef(null)

  const navItems = [
    ...NAV_ITEMS,
    catalogueStore.promotionsPlusNavItem,
    catalogueStore.infoNavItem,
    ...catalogueStore.specialEquipmentCodeNavItems,
    ...catalogueStore.categoryMenu,
  ]

  const focusSearch = () => {
    searchInputRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
    searchInputRef.current.focus({ preventScroll: true })
  }

  useEffect(() => {
    const scrollFun = (e) => {
      // check to see how large the body is (measured in pixels)
      if (document.documentElement.scrollTop > 0) {
        // setting timeout so we don't trigger it over and over again
        setUseLargeHeader(false)
      } else if (document.documentElement.scrollTop === 0) {
        setUseLargeHeader(true)
      }
    }
    window.addEventListener("scroll", scrollFun)
    return () => window.removeEventListener("scroll", scrollFun)
  }, [])

  return (
    <NoSSR>
      <Show above="md">
        <Box id="DesktopHeader" bg={useColorModeValue("white", "gray.800")} position="sticky" top={0} zIndex={10}>
          <WarningBanner />
          <DesktopHeader useLargeHeader={useLargeHeader} cartProductCount={cartProductCount} />
          <Flex as={Show} above="md">
            <DesktopNav navItems={navItems} />
          </Flex>
        </Box>
      </Show>
      <Show below="md">
        <MobileHeader cartProductCount={cartProductCount} navItems={navItems} onClickSearchIcon={focusSearch} />
        {userStore.currentUser?.isRep && (
          <Flex h={12} bg="gray.200" justify="center">
            <SelectClient />
          </Flex>
        )}
        <SearchBar ref={searchInputRef} />
      </Show>
    </NoSSR>
  )
})
