import { Instance, types } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../lib"

export const CompanyModel = types
  .model("CompanyModel")
  .props({
    id: types.identifierNumber,
    name: types.maybeNull(types.string),
    imageUrl: types.maybeNull(types.string),
    websiteUrl: types.maybeNull(types.string),
    slug: types.maybeNull(types.string),
  })
  .extend(withRootStore())
  .extend(withEnvironment())

export interface ICompany extends Instance<typeof CompanyModel> {}
