import { flow, Instance, types } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../lib"
import { ProductCategoryModel, ServiceItemGroupModel, SpecialEquipmentCodeModel } from "../models"
import { NavItem } from "../app/components/shared/nav/nav-items"
import { WEB_ORDER_TYPE } from "../utils/constants"
import { IOtherPageModel, ISystemSetting, IProduct, IBlogPostModel } from "../types/types"
import { rootStore } from "../app/setup"

export const CatalogueStoreModel = types
  .model("CatalogueStoreModel")
  .props({
    instagramImages: types.array(types.frozen()),
    desktopImageUrls: types.array(types.string),
    isLoading: types.maybeNull(types.boolean),
    announcements: types.array(types.frozen()),
    productCategories: types.array(ProductCategoryModel),
    specialEquipmentCodes: types.array(SpecialEquipmentCodeModel),
    serviceItemGroups: types.array(ServiceItemGroupModel),
    otherPages: types.array(types.frozen<IOtherPageModel>()),
    blogPosts: types.array(types.frozen<IBlogPostModel>()),
    systemSetting: types.frozen<ISystemSetting>(),
    suggestedProducts: types.array(types.frozen<IProduct>()),
    amountForFreeFrozenShipping: types.maybeNull(types.number),
    amountForNonFreeFrozenShipping: types.maybeNull(types.number),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    get memberAccessOtherPages() {
      return self.otherPages.filter((op) => op.memberAccess)
    },
    get nonMemberAccessOtherPages() {
      return self.otherPages.filter((op) => !op.memberAccess)
    },
    get splitDesktopImageUrls() {
      const arr = self.desktopImageUrls
      const middleIndex = Math.floor(arr.length / 2) // Find the middle index of the array
      const firstHalf = arr.slice(0, middleIndex) // Slice the first half of the array
      const secondHalf = arr.slice(middleIndex) // Slice the second half of the array
      return [firstHalf, secondHalf] // Return both halves as an array
    },
    get childCategories() {
      return self.productCategories.map((c) => c.childCategories).flat()
    },
    get parentCount() {
      return self.productCategories?.length || 0
    },
    get categoryMenu(): NavItem[] {
      const mapCategory = (c) => {
        return {
          key: c.id,
          label: c.name,
          imageUrl: c.imageUrl,
          href: `/categories/${c.slug || c.id}`,
          children: c.childCategories ? c.childCategories.map((ch) => mapCategory(ch)) : null,
        }
      }
      return self.productCategories.map((c) => mapCategory(c)) || []
    },
    get promotionsPlusNavItem(): NavItem {
      const navItems = {
        key: "promotions-plus",
        label: "Promotions+",
        fontSize: "20px",
        color: "brand.orange",
        fontWeight: "bold",
        children: [
          ...self.serviceItemGroups
            .filter((sig) => sig.orderType == null || sig.orderType == WEB_ORDER_TYPE)
            .map((sig) => {
              return {
                key: sig.code.toLowerCase(),
                label: sig.description,
                href: `/service-item-groups/${sig.slug || sig.id}`,
              }
            }),
        ],
      }
      if (self.rootStore.sessionStore.isLoggedIn) {
        navItems.children.unshift({
          key: "promotions",
          label: "Promotions",
          href: "/promotions",
          // subLabel: 'This is how you do a sub-label'
        })
      }
      return navItems
    },

    get specialEquipmentCodeNavItems(): NavItem[] {
      return self.specialEquipmentCodes
        .map((sec) => {
          return (
            sec.showInNav && {
              key: sec.code.toLowerCase(),
              label: sec.description,
              href: `/special-equipment-codes/${sec.slug || sec.id}`,
            }
          )
        })
        .filter((e) => e)
    },
  }))
  .views((self) => ({
    get footerList() {
      let items = [
        ...self.nonMemberAccessOtherPages
          .filter((op) => op.showInMenu)
          .map((op) => {
            return {
              key: op.slug,
              label: op.name,
              href: `/info/${op.slug}`,
            }
          }),
      ]
      if (rootStore.sessionStore.isLoggedIn) {
        items = items.concat(
          ...self.memberAccessOtherPages.map((op) => {
            return {
              key: op.slug,
              label: op.name,
              href: `/info/${op.slug}`,
            }
          }),
        )
      }
      return items
    },
    get infoNavItem(): NavItem {
      const items = {
        key: "nav-info",
        label: "Info",
        children: [
          ...self.nonMemberAccessOtherPages
            .filter((op) => op.showInMenu)
            .map((op) => {
              return {
                key: op.slug,
                label: op.name,
                href: `/info/${op.slug}`,
              }
            }),
          {
            key: "vendor-list",
            label: "Vendor List",
            href: "/info/vendor-list",
          },
          {
            key: "teams",
            label: "Meet the Team",
            href: "/teams",
          },
          {
            key: "partner-with-us",
            label: "Partner With Us",
            href: "/partner-with-us",
          },
        ],
      }
      if (rootStore.sessionStore.isLoggedIn) {
        items.children = items.children.concat(
          ...self.memberAccessOtherPages.map((op) => {
            return {
              key: op.slug,
              label: op.name,
              href: `/info/${op.slug}`,
            }
          }),
        )
      }
      return items
    },
    getCategory(id: string | number) {
      return (
        self.productCategories.find((c) => c.id == id || c.slug == id) ||
        self.childCategories.find((c) => c.id == id || c.slug == id)
      )
    },
    getServiceItemGroup(id: string | number) {
      return self.serviceItemGroups.find((c) => c.id == id || c.slug == id)
    },
    getSpecialEquipmentCode(id: string | number) {
      return self.specialEquipmentCodes.find((c) => c.id == id || c.slug == id)
    },
  }))
  .actions((self) => ({
    load: flow(function* () {
      self.isLoading = true
      const response = yield self.environment.api.fetchCatalogue()
      if (response && response.ok && response.data) {
        // Grabbing categories, annoucements and desktopimageurls from app home screen content
        self.productCategories = response.data.categories
        self.specialEquipmentCodes = response.data.specialEquipmentCodes
        self.serviceItemGroups = response.data.serviceItemGroups
        self.announcements = response.data.announcements
        self.desktopImageUrls = response.data.desktopImageUrls || []
        self.instagramImages = response.data.instagramImages || []
        self.otherPages = response.data.otherPages
        self.blogPosts = response.data.blogPosts
        self.systemSetting = response.data.systemSettings
        self.suggestedProducts = response.data.suggestedProducts
        self.isLoading = false
      }
    }),
    updateSuggestedProducts: flow(function* (products = []) {
      self.suggestedProducts = products
    }),
  }))

export interface ICatalogueStore extends Instance<typeof CatalogueStoreModel> {}
