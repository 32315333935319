import { flow, IMSTArray, Instance, types } from "mobx-state-tree"
import { values } from "mobx"
import { string } from "mobx-state-tree/dist/internal"
import { withEnvironment, withRootStore } from "../lib"
import { CompanyModel, ICompany } from "../models/company"

export const CompanyStoreModel = types
  .model("CompanyStoreModel")
  .props({
    companies: types.array(types.frozen<ICompany>()),
    page: types.optional(types.number, 1),
    totalPages: types.maybeNull(types.number),
    isLoading: types.optional(types.boolean, false),
    isRefreshing: types.optional(types.boolean, false),
    currentCompany: types.maybeNull(CompanyModel),
    isSearchingCompany: types.optional(types.boolean, false),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .actions((self) => ({
    getCompanies: flow(function* () {
      return yield self.environment.api.getCompanies(self.page)
    }),
    setIsLoading(value: boolean) {
      self.isLoading = value
    },
    setIsRefreshing(value: boolean) {
      self.isRefreshing = value
    },
    fetchCompany: flow(function* (id) {
      self.isSearchingCompany = true
      const response = yield self.environment.api.getCompanyById(id)
      if (response.ok) {
        self.currentCompany = response.data
      }
      self.isSearchingCompany = false
    }),
  }))
  .views((self) => ({
    // getCompany(id: string|number) {
    //   return self.companies.find(c => c.id == id || c.slug == id)
    // },
  }))
  .actions((self) => ({
    getInitialResults: flow(function* () {
      self.page = 1
      const response = yield self.getCompanies()
      if (response.ok) {
        self.companies = response.data.companies
        self.totalPages = response.data.totalPages
      }
    }),
    getNextPage: flow(function* () {
      if (self.totalPages == self.page) return
      self.isLoading = true
      self.page++
      const response = yield self.getCompanies()
      if (response.ok) {
        self.companies = self.companies.concat(response.data.companies) as IMSTArray<any>
      }
      self.isLoading = false
    }),
  }))

export interface ICompanyStore extends Instance<typeof CompanyStoreModel> {}
