import { flow, IStateTreeNode, types } from "mobx-state-tree"
import {
  BarcodeStoreModel,
  CartStoreModel,
  CatalogueStoreModel,
  ClientSearchStoreModel,
  ClientStoreModel,
  CompanyStoreModel,
  CreditStoreModel,
  DealStoreModel,
  IBarcodeStore,
  ICartStore,
  ICatalogueStore,
  IClientSearchStore,
  IClientStore,
  ICompanyStore,
  ICreditStore,
  IDealStore,
  IOrderTemplateStore,
  IProductActionsStore,
  IProductSearchStore,
  ISalesOrderStore,
  ISalesInvoiceStore,
  ISessionStore,
  ITeamsStore,
  IUIStore,
  IUserStore,
  OrderTemplateStoreModel,
  ProductActionsStoreModel,
  ProductSearchStoreModel,
  SalesOrderStoreModel,
  SalesInvoiceStoreModel,
  SessionStoreModel,
  ContentStoreModel,
  UIStoreModel,
  UserStoreModel,
  BlogStoreModel,
  IBlogStore,
} from "."
import persist from "../lib/mst-persist/persist"
import { localStorageTest } from "../utils"
import { IPromotionStore, PromotionStoreModel } from "./promotion-store"
import { INotificationStore, NotificationStoreModel } from "./notification-store"

export const RootStoreModel = types
  .model("RootStoreModel")
  .props({
    barcodeStore: types.optional(BarcodeStoreModel, {}),
    blogStore: types.optional(BlogStoreModel, {}),
    cartStore: types.optional(CartStoreModel, { addingInProgress: false }),
    catalogueStore: types.optional(CatalogueStoreModel, {}),
    clientSearchStore: types.optional(ClientSearchStoreModel, {}),
    clientStore: types.optional(ClientStoreModel, {}),
    companyStore: types.optional(CompanyStoreModel, {}),
    creditStore: types.optional(CreditStoreModel, {}),
    dealStore: types.optional(DealStoreModel, {}),
    orderTemplateStore: types.optional(OrderTemplateStoreModel, {}),
    productActionsStore: types.optional(ProductActionsStoreModel, {}),
    productSearchStore: types.optional(ProductSearchStoreModel, {}),
    salesOrderStore: types.optional(SalesOrderStoreModel, {}),
    promotionStore: types.optional(PromotionStoreModel, {}),
    salesInvoiceStore: types.optional(SalesInvoiceStoreModel, {}),
    sessionStore: types.optional(SessionStoreModel, {}),
    contentStore: types.optional(ContentStoreModel, {}),
    uiStore: types.optional(UIStoreModel, {}),
    userStore: types.optional(UserStoreModel, {}),
    notificationStore: types.optional(NotificationStoreModel, {}),
    loaded: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    startup: flow(function* () {
      // MOBILE ONLY
      // yield rootStore.sessionStore.checkKeychainCredentials()
      {
        if (localStorageTest()) {
          const previousUserStoreId = sessionStorage.getItem("sessionSavedId")

          // SET UP PERSISTENCE
          yield persist("userStore", self.userStore, {
            // storage: localForage, // or AsyncStorage in react-native. (default: localStorage)
            // jsonify: false, // if you use AsyncStorage, this shoud be true (default: true)
            whitelist: ["currentUser"], // only these keys will be persisted
          })
          yield persist("sessionStore", self.sessionStore, {
            whitelist: ["isLoggedIn", "expiry"],
          })
          // Cart persistence disabled for now as causes outdated subtotal to get stuck when there are pricer errors on replace cart
          yield persist("cartStore", self.cartStore, {
            // whitelist: ['cart', 'cartItemsInProgressMap', 'addingInProgress']
            whitelist: ["cart"],
          })

          const response = yield self.sessionStore.validateToken()
          const currentErpCustomerId = response?.data?.data?.erpCustomer?.number
          const isRep = response?.data?.data?.isRep
          if (isRep && previousUserStoreId && currentErpCustomerId && previousUserStoreId != currentErpCustomerId) {
            alert(
              "Your selected ERP customer has been updated. Please use the customer selector if you would like to switch clients.",
            )
          }
          yield self.catalogueStore.load()
        } else {
          //if local storage does not work, just load the catalogue, but call validate token just in case
          self.sessionStore.validateToken()
          yield self.catalogueStore.load()
        }
      }
    }),
  }))
  .actions((self) => ({
    afterCreate() {
      if (process.env.NEXT_PUBLIC_MAINTENANCE_MODE === "true") {
        return
      }
      self.startup()
    },
  }))

export interface IRootStore extends IStateTreeNode {
  barcodeStore: IBarcodeStore
  blogStore: IBlogStore
  cartStore: ICartStore
  catalogueStore: ICatalogueStore
  clientSearchStore: IClientSearchStore
  clientStore: IClientStore
  companyStore: ICompanyStore
  creditStore: ICreditStore
  dealStore: IDealStore
  orderTemplateStore: IOrderTemplateStore
  productActionsStore: IProductActionsStore
  productSearchStore: IProductSearchStore
  salesOrderStore: ISalesOrderStore
  salesInvoicesStore: ISalesInvoiceStore
  sessionStore: ISessionStore
  contentStore: ITeamsStore
  uiStore: IUIStore
  userStore: IUserStore
  promotionStore: IPromotionStore
  notificationStore: INotificationStore
}
