import { flow, Instance, types } from "mobx-state-tree"
import { translate } from "../app/i18n"
import { withEnvironment, withRootStore } from "../lib"
import { SalesInvoiceModel } from "../models/sales-invoice"

export const SalesInvoiceStoreModel = types
  .model("SalesInvoiceStoreModel")
  .props({
    salesInvoiceList: types.maybeNull(types.array(SalesInvoiceModel)),
    currentSalesInvoice: types.maybeNull(types.reference(SalesInvoiceModel)),
    startDate: types.maybeNull(types.string),
    endDate: types.maybeNull(types.string),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({}))
  .actions((self) => ({
    fetchSalesInvoices: flow(function* (params) {
      const response = yield self.environment.api.searchSalesInvoices(params)
      if (response.ok) {
        self.salesInvoiceList = response.data.values
        self.startDate = params.invoiceDateGteq
        self.endDate = params.invoiceDateLteq
      } else {
        self.rootStore.uiStore.flashMessage.show(translate("error.genericTitle"), response?.data?.error, "danger", {})
      }
    }),
    setCurrentSalesInvoice(salesInvoice) {
      self.currentSalesInvoice = salesInvoice?.id
    },
    resetCurrentSalesInvoice() {
      self.currentSalesInvoice = null
    },
  }))

export interface ISalesInvoiceStore extends Instance<typeof SalesInvoiceStoreModel> {}
