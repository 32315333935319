import { flow, IMSTArray, Instance, types } from "mobx-state-tree"
import { translate } from "../app/i18n"
import { values, toJS } from "mobx"
import * as R from "ramda"
import { withEnvironment, withRootStore } from "../lib"
import { CartModel, IPromotion, PromotionModel } from "../models"
import { EPromotionFilterKeys, EPromotionStatus, IFilterOption, IPromotionGroup } from "../types"

export const SORT_OPTIONS = {
  "Expiry Date Ascending": R.sort((a, b) => a.endDate.getTime() - b.endDate.getTime()),
  "Expiry Date Descending": R.sort((a, b) => b.endDate.getTime() - a.endDate.getTime()),
  "Starting Date Ascending": R.sort((a, b) => a.startDate.getTime() - b.startDate.getTime()),
  "Starting Date Descending": R.sort((a, b) => b.startDate.getTime() - a.startDate.getTime()),
  "Name Ascending": R.sort((a, b) => a.name.localeCompare(b.name)),
  "Name Descending": R.sort((a, b) => b.name.localeCompare(a.name)),
  Default: R.sort((a, b) => b.position - a.position),
}

export const PromotionStoreModel = types
  .model("PromotionStoreModel")
  .props({
    isLoading: types.maybeNull(types.boolean),
    promotionMap: types.map(PromotionModel),
    brand: types.optional(types.array(types.string), []),
    tag: types.optional(types.array(types.string), []),
    filterTerm: types.optional(types.string, ""),
    sortBy: types.optional(types.string, ""),
    isSearching: types.maybeNull(types.boolean),
    page: types.optional(types.number, 1),
    totalPages: types.maybeNull(types.number),
    resultsCount: types.maybeNull(types.number),
    cart: types.maybeNull(CartModel),
    addingInProgress: types.maybeNull(types.boolean),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    get promotions(): IPromotion[] {
      //@ts-ignore
      return values(self.promotionMap)
    },
  }))
  .views((self) => ({
    get featuredPromotions(): IPromotion[] {
      //@ts-ignore
      return self.promotions.filter((p) => p.featured)
    },
    getPromotionBySlug(slug): IPromotion {
      //@ts-ignore
      return self.promotions.find((promo) => promo.slug === slug)
    },
    get promotionBrandOptions(): IFilterOption[] {
      const brandMap = self.promotions.map((promo) => promo.brand).filter((outFalsy) => outFalsy)
      return R.uniq(brandMap).map((brand) => ({ displayName: brand, key: brand, applied: true }))
    },
    get promotionTagOptions(): IFilterOption[] {
      const tagMap = self.promotions.reduce((acc, cur) => acc.concat(cur.extendedTags), [])
      return R.uniq(tagMap).map((tag) => ({ displayName: tag, key: tag, applied: true }))
    },
    get filteredGroupedPromotions(): IPromotion[][] {
      if (self.promotions.length === 0) return null

      const byGroup = R.pipe(
        R.groupBy((promo) => promo.promotionGroup?.position),
        R.toPairs,
        R.sortBy(R.prop(0)),
        R.map(R.prop(1)),
      )

      const filterByBrand = R.filter(
        R.propSatisfies((brand) => R.includes(brand, self.brand) || self.brand?.length === 0, "brand"),
      )
      const filterByTags = R.filter(
        R.propSatisfies(
          (tags) => R.intersection(tags || [], self.tag).length > 0 || self.tag?.length === 0,
          "extendedTags",
        ),
      )
      const filterByTerm = R.filter(
        R.propSatisfies((name) => name.toLowerCase().includes(self.filterTerm.toLowerCase()), "name"),
      )
      const sort = SORT_OPTIONS[self.sortBy] || ((v) => v)
      return R.pipe(filterByBrand, filterByTags, filterByTerm, sort, byGroup)(self.promotions)
    },
    promotionsByGroupId(groupId): IPromotion[] {
      return self.promotions.filter((promo) => promo.promotionGroup.id === groupId)
    },
  }))
  .actions((self) => ({
    toggleFilterOption(option: { [key: string]: any }, filterKey: EPromotionFilterKeys) {
      const isChecked = self[filterKey] && self[filterKey].length > 0 ? R.includes(option.key, self[filterKey]) : false

      if (isChecked) {
        self[filterKey].remove(option.key)
      } else {
        self[filterKey].push(option.key)
      }
    },
    setFilterTerm(term) {
      self.filterTerm = term
    },
    setSortBy(term) {
      self.sortBy = term
    },
    clearFilterKey(filterKey) {
      self[filterKey] = []
    },
    setPromotions(promotions) {
      promotions.forEach((promo) => self.promotionMap.put(promo))
    },
  }))
  .actions((self) => ({
    fetchPromotions: flow(function* () {
      const response = yield self.environment.api.fetchPromotions()
      if (response.data.errors) return

      self.setPromotions(response.data)
    }),
    fetchPromotion: flow(function* (id) {
      const response = yield self.environment.api.fetchPromotion(id)
      if (response.data.errors) return

      self.promotionMap.put(response.data)
    }),
    applyPromotion: flow(function* (promotionId, values) {
      self.cart = null
      self.addingInProgress = true
      const response = yield self.environment.api.applyPromotion(promotionId, values)
      self.addingInProgress = false
      if (response.ok) {
        self.cart = response.data
        return response
      } else {
        self.rootStore.uiStore.flashMessage.showAddToCart(
          translate("reviewPromotionButton.title"),
          translate("reviewPromotionButton.errorMessage"),
          "error",
          {},
        )
        return response
      }
    }),
    promotionCheckout: flow(function* (orderNotes) {
      try {
        return yield self.environment.api.checkout(orderNotes, null, true)
      } catch {
        // error messaging handled by API monitor
      }
    }),
  }))

export interface IPromotionStore extends Instance<typeof PromotionStoreModel> {}
