import {
  Box,
  Divider,
  Flex,
  Image,
  HStack,
  Text,
  BoxProps,
  Center,
  Stack,
  Button,
  Spacer,
  IconButton,
  Spinner,
  Tag,
  VStack,
  Show,
  Link,
} from "@chakra-ui/react"
import { IProduct } from "../../../types"
import { ProductQuantityPicker } from "../domains/product/product-quantity-picker"
import { formatAsPrice, priceStyleSplitter } from "../../../utils/formatter"
import * as R from "ramda"
import { useMst } from "../../../lib/hooks/use-mst"
import { ShoppingCartIcon } from "../../../../public/images/icon/shopping-cart-icon"
import NextLink from "next/link"
import { DottedBox } from "../domains/product/dotted-box"
import { BellIcon, CloseIcon } from "@chakra-ui/icons"
import { useEffect, useState } from "react"
import { LightningBolt } from "../../../../public/images/icon/lightning-bolt"
import { LinkButton } from "./ui"

export interface IProductCardProps extends BoxProps {
  product: IProduct
  quantity: number
  compact?: boolean
  onClickClose?: (any) => void
  setQuantity: (updatedQuantity: number) => void
  handleAdd?: (any: any) => void
}

export const ProductCard = ({
  product,
  quantity,
  setQuantity,
  compact,
  onClickClose,
  ...boxProps
}: IProductCardProps) => {
  const [price, setPrice] = useState(priceStyleSplitter(product.price))

  useEffect(() => {
    if (product.effectiveSalesPrice) setPrice(priceStyleSplitter(product.effectiveSalesPrice || product.price))
  }, [product])

  const {
    sessionStore: { isLoggedIn },
    cartStore,
    notificationStore,
    uiStore: { isMobileDevice },
    productSearchStore: { isLoadingCustomerBestPrices },
  } = useMst()

  const [followDisabled, setFollowDisabled] = useState(false)

  const currentItem = cartStore.cart?.cartItems?.find(
    (cartItem) => product.anipetItemNumber == cartItem.product.anipetItemNumber,
  )

  const handleClickNotifyMe = () => {
    if (notificationStore.createProductFollow(product.id)) setFollowDisabled(true)
  }

  const pickerBlock = (product: IProduct, compact: boolean) => {
    if (product.comingSoon && !compact) {
      return (
        <VStack>
          <DottedBox>Coming Soon</DottedBox>
          <LinkButton
            disabled={product.currentUserIsFollowing || followDisabled}
            leftIcon={<BellIcon />}
            color="background.brandOrange"
            onClick={handleClickNotifyMe}>
            Notify Me
          </LinkButton>
        </VStack>
      )
    }
    if (product.blocked) {
      return <DottedBox>Currently Unavailable</DottedBox>
    }
    return (
      <Flex direction="column" h="full" justify="center" align="flex-end">
        {product.effectiveSalesPrice && parseFloat(product.price) > product.effectiveSalesPrice && (
          <Text fontSize="3xl" mb={2} color="gray.400" as="s" fontFamily={"Pathway Gothic One"}>
            {formatAsPrice(product.price)}
          </Text>
        )}
        <ProductQuantityPicker
          compact={compact}
          quantity={quantity}
          setQuantity={setQuantity}
          salesUnitOfMeasure={product.salesUnitOfMeasure}
          displaySUOM={false}
        />
        {product.lineDiscountPercentage > 0 && (
          <Tag
            alignSelf="center"
            mt={2}
            fontSize="16px"
            gap={1}
            maxW={compact ? "124px" : "160px"}
            whiteSpace="nowrap"
            borderRadius="100px"
            height="25px"
            background="#0099CC">
            <LightningBolt />
            <span style={{ textOverflow: "ellipsis", overflow: "hidden", color: "white" }}>
              {product.lineDiscountPercentage}% off all {product.brandName}
            </span>
          </Tag>
        )}
      </Flex>
    )
  }

  return (
    <Box
      className="product-card-box"
      backgroundColor="background.white"
      p={{ base: 2, md: 4 }}
      pb={4}
      borderBottom="1px solid"
      borderColor="gray.200"
      {...boxProps}>
      <Flex
        className="product-card-flex"
        w="full"
        justifyContent={"flex-start"}
        alignItems={{ base: "flex-start", md: "center" }}
        h="full"
        direction={{ base: "column", md: "row" }}>
        <Flex width={{ base: "full", md: "fit-content" }} justify="space-between" align="center">
          {product.sample && (
            <Center
              fontSize={compact ? "xs" : "sm"}
              fontWeight="semibold"
              color="white"
              h={20}
              w={compact ? "64px" : "100px"}
              position="absolute"
              bg="background.brandOrange">
              SAMPLE
            </Center>
          )}
          {!R.isEmpty(product?.imageUrls) && (
            <Image
              minW={compact ? "64px" : "100px"}
              w={compact ? "64px" : "100px"}
              h={compact ? "64px" : "100px"}
              src={product?.thumbImageUrls?.[0] || product?.imageUrls?.[0]}
              objectFit="cover"
              mr={2}
              alt="product image"
            />
          )}
          {onClickClose && (
            <IconButton
              aria-label="Delete notification"
              bg="transparent"
              onClick={onClickClose}
              icon={<CloseIcon h={2} w={2} />}
              ml={{ base: "0", md: "2" }}
            />
          )}
        </Flex>

        <Flex direction="column" className="growflex" w="full">
          <NextLink
            key={product?.id}
            className="product-card-name-link"
            href={`/products/${product?.slug || product?.id}`}
            passHref>
            {product?.name}
          </NextLink>

          <Flex
            align="center"
            gap={2}
            flexGrow={1}
            direction={{ base: "column", md: "row" }}
            alignItems={{ base: "flex-start", md: "center" }}>
            <Flex direction="column" align="flex-start" flexGrow={1}>
              <Flex gap={2} minW="120px">
                <Text color="gray.400" fontSize="16px" noOfLines={1}>
                  Item No.
                </Text>
                <Text color="gray.400" fontSize="18px">
                  {product?.anipetItemNumber}
                </Text>
              </Flex>
              <Show below="md">
                <Text fontSize="14px" color="gray.400">
                  {product?.brandName} | {product?.vendorItemNumber} | {product?.itemLocation}
                </Text>
              </Show>
              {product?.specialEquipmentCode && (
                <HStack pt="2">
                  <Image
                    minW="25px"
                    minH="25px"
                    w="25px"
                    h="25px"
                    src={product?.specialEquipmentCode.imageUrl}
                    alt="special equipment code"
                  />
                  <Text mt="1" fontSize="12px" lineHeight="14.58px" color="background.brandBlueSecondary">
                    {product?.specialEquipmentCode.description}
                  </Text>
                </HStack>
              )}
            </Flex>

            {currentItem?.quantity > 0 && (
              <Box justifyContent="center" minW="50px" flexShrink={1}>
                <Text color="gray" textAlign="center">
                  {currentItem.quantity} in <ShoppingCartIcon />
                </Text>
              </Box>
            )}

            {isLoggedIn && (
              <Flex justify={{ base: "space-between", md: "flex-end" }} w="full">
                <Stack direction={{ base: "column", md: "row" }} gap={3} h="full">
                  {product?.salePrice && (
                    <Text
                      fontSize="27px"
                      textDecorationLine="line-through"
                      color="gray"
                      fontFamily={"Pathway Gothic One"}>
                      ${product?.salePrice}
                    </Text>
                  )}
                  {pickerBlock(product, compact)}
                </Stack>
                <Flex direction={{ base: "row", md: "column" }} align="flex-end" minW={{ base: "57px", md: "83px" }}>
                  {isLoadingCustomerBestPrices ? (
                    <Spinner color="brand.orange" ml={6} />
                  ) : (
                    <Flex align="flex-start" textAlign="right" justify="flex-end">
                      <Text fontSize="4xl" color="brand.orange" fontFamily="Pathway Gothic One">
                        ${price[0]}.
                      </Text>
                      <Text fontSize="md" color="brand.orange" fontFamily="Pathway Gothic One" mt={2}>
                        {price[1]}
                      </Text>
                    </Flex>
                  )}
                  <Text textAlign="right" fontSize="sm" color="gray.500">
                    /{product?.salesUnitOfMeasure}
                  </Text>
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}
